<template>
  <app-layout>
    <div
      class="w-full flex justify-center rounded-lg items-center md:w-6/12 md:mx-auto"
      :class="haveAssociate ? '' : 'hidden'"
    >
      <div class="w-11/12 my-10 p-6 bg-white bg-opacity-90 shadow-xl">
        <p
          class="font-bold mb-3 text-lg md:text-xl xl:text-2xl text-green-1002 text-center bold"
        >
          Encontramos um associado com o documento informado no
          {{ syndicate.name }}
        </p>
        <p
          class="font-bold mb-3 text-lg md:text-xl xl:text-2xl text-green-1002 text-center bold"
        >
          Entre em contato com seu sindicato para cadastrar seus acessos.
        </p>
        <span
          class="font-bold mb-3 text-lg md:text-xl xl:text-2xl text-green-1002 text-left bold"
          >Contato:
        </span>
        <the-mask
          class="font-bold mb-3 text-lg md:text-xl xl:text-2xl text-green-1002 text-left bold"
          :mask="['(##) ####-####', '(##) #####-####']"
          :value="syndicate.telephone"
          readonly
        ></the-mask>

        <p class="text-right">
          <button
            @click="goBack"
            class="mx-8 my-4 cursor-pointer font-bold text-white bg-green-1003 px-5 py-1 rounded-full"
          >
            Voltar
          </button>
        </p>
      </div>
    </div>
    <div
      class="w-full flex justify-center rounded-lg items-center md:w-10/12 md:mx-auto"
      :class="haveAssociate ? 'hidden' : ''"
    >
      <section class="hidden md:flex px-0 text-gray-600">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <div class="flex flex-wrap -m-4">
            <div class="p-4 md:w-full">
              <div
                class="bg-white rounded-xl border-opacity-50 p-8 sm:flex bg-opacity-70"
              >
                <div
                  class="w-16 h-16 sm:mr-4 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0"
                >
                  <img
                    :src="require('../../assets/images/gold-badge.png')"
                    alt=""
                  />
                </div>
                <div class="flex-grow">
                  <h2
                    class="hidden md:block font-bold text-lg md:text-xl xl:text-3xl text-green-1002 bold"
                  >
                    TEMOS MAIS DE
                    <span class="text-green-1003 font-bold">200.000</span>
                    TALENTOS AGUARDANDO POR SUA VAGA
                  </h2>
                  <p
                    class="sm:text-3xl text-2xl font-medium text-gray-900 mt-2 mb-2"
                  >
                    Produtor e Empresas do Agro, faça seu cadastro para
                    divulgação de suas vagas e ter acesso a nossos Talentos
                    qualificados pelo SENAR-MT.
                  </p>
                  <button
                    class="mt-3 text-indigo-500 inline-flex justify-end items-center"
                    onclick="window.open('https://www.youtube.com/watch?v=dDNryAfVfdo&ab_channel=SenarMT', '_blank')"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z"
                        fill="rgba(255,0,0,1)"
                      />
                    </svg>
                    Video Passo a Passo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        class="w-10/12 md:w-6/12 my-10 p-6 rounded-lg bg-white bg-opacity-90 shadow-xl"
      >
        <p
          class="font-bold text-lg md:text-xl xl:text-2xl text-green-1002 text-center bold"
        >
          Cadastre sua empresa
        </p>
        <form @submit.prevent="submit">
          <div class="flex w-full mb-5 mt-10">
            <div class="flex w-full flex-col">
              <label class="mb-2" for="entity"
                >Empregador (Pessoa Fisica ou Pessoa Juridida)</label
              >
              <multiselect
                id="entity"
                v-model="$v.associate.entities.selected.$model"
                deselect-label="Remover"
                track-by="label"
                label="label"
                select-label="Selecionar"
                selected-label="Selecionado"
                :searchable="false"
                placeholder="Selecione uma entidade"
                :options="associate.entities.options"
                @select="toggleDocument"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg leading-tight focus:outline-none focus:bg-white"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.label }}
                </template>
                <span slot="noOptions">Nenhum registro encontrado.</span>
              </multiselect>
              <div v-if="$v.associate.entities.selected.$error">
                <div
                  class="error"
                  v-if="!$v.associate.entities.selected.required"
                >
                  Campo obrigatório.
                </div>
              </div>
            </div>
          </div>

          <div class="flex w-full mb-5">
            <div class="flex w-full flex-col">
              <label class="mb-2"
                >Documento da Empresa ou Propriedade Rural (CPF ou CNPJ)</label
              >
              <input
                v-model="$v.associate.document.$model"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                type="text"
                placeholder="Documento (CPF ou CNPJ)"
                v-mask="documentMask"
                autocomplete="off"
              />
              <div v-if="$v.associate.document.$error">
                <div class="error" v-if="!$v.associate.document.required">
                  Campo obrigatório.
                </div>
              </div>
            </div>
          </div>

          <button
            type="submit"
            class="py-1 bg-green-1002 text-white text-center w-full mt-5 text-lg focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
            :disabled="submitting"
          >
            {{ buttonText }}
          </button>
        </form>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from '../../layouts/AppLayout';
import { required } from 'vuelidate/lib/validators';
import { mask, TheMask } from 'vue-the-mask';
import axios from '@/utils/axios';

export default {
  name: 'Validation',

  title() {
    return `${process.env.VUE_APP_NAME} | Recrutador - Validação`;
  },

  components: {
    AppLayout,
    TheMask
  },

  directives: { mask, TheMask },

  data() {
    return {
      submitting: false,
      isCpf: false,
      haveAssociate: false,
      syndicate: {
        name: '',
        telephone: ''
      },
      associate: {
        document: '',
        entities: {
          options: [
            { label: 'Pessoa Física', value: 1 },
            { label: 'Pessoa Jurídica', value: 2 }
          ],
          selected: null
        }
      }
    };
  },

  validations: {
    associate: {
      document: { required },
      entities: {
        selected: { required }
      }
    }
  },

  computed: {
    buttonText() {
      return this.submitting ? 'VALIDANDO...' : 'VERIFICAR';
    },

    documentMask() {
      return this.isCpf ? '###.###.###-##' : '##.###.###/####-##';
    }
  },

  methods: {
    toggleDocument(entity) {
      this.isCpf = entity.value === 1;
    },

    goBack() {
      this.$router.go(-1);
    },

    submit() {
      if (this.$v.associate.$invalid) {
        this.$v.associate.document.$touch();
        this.$v.associate.entities.selected.$touch();
      } else {
        this.submitting = true;

        localStorage.setItem(
          'createAccess',
          JSON.stringify({
            document: this.associate.document,
            entity: this.associate.entities.selected
          })
        );

        axios
          .post('/api/cadastrar-empresa/', {
            entity: this.associate.entities.selected.value,
            document: this.associate.document,
            associate_external: true
          })
          .then(({ data }) => {
            if (data.data.length == 1) {
              this.haveAssociate = true;
              this.syndicate.name = data.data[0].syndicate.name;
              this.syndicate.telephone = data.data[0].syndicate.telephone;
            } else {
              this.$router.push({
                name: 'create-access'
              });
            }
          })
          .catch(({ response }) => {
            Object.values(response.data.errors).forEach(error => {
              this.$toast.error(...error);
            });
          })
          .finally(() => (this.submitting = false));
      }
    }
  }
};
</script>

<style scoped></style>
